/*
 *   Module Loader
 *   Consumes an array of .js-modules and parses data-module attribute to produce an array
 *   of module names to be loaded.
 *
 *   Markup must include class="js-module" and
 *   data-module="['my-module-1', 'my-module-2'"]
 *
 */

import { camelize } from '@/ui/scripts/lib/camelize';

const PL_DEBUG = window.plDebug || false;

function load(key: string) {
  const modules: Hash = {
    // Accordion
    accordion: async () => {
      const { default: accordion } = await import(
        '@/ui/templates/02-components/accordion/mando-ui.accordion'
      );
      accordion();
      if (PL_DEBUG) console.log(`%c${key} loaded`, 'color: #000; background: yellow;');
    },
    // Nav desktop
    navDesktop: async () => {
      const { default: navDesktop } = await import('@/app/views/nav-desktop/nav-desktop');
      navDesktop();
      if (PL_DEBUG) console.log(`%c${key} loaded`, 'color: #000; background: yellow;');
    },
    // Nav mobile
    navMobile: async () => {
      const { default: navMobile } = await import('@/app/views/nav-mobile/nav-mobile');
      navMobile();
      if (PL_DEBUG) console.log(`%c${key} loaded`, 'color: #000; background: yellow;');
    },
    // Rich Text Table
    rteTable: async () => {
      const { default: rteTable } = await import(
        '@/ui/templates/02-components/_rte-table/mando-ui.rte-table'
      );
      rteTable();
      if (PL_DEBUG) console.log(`%c${key} loaded`, 'color: #000; background: yellow;');
    },
    // Searchbar
    searchbar: async () => {
      const { default: searchbar } = await import(
        '@/ui/templates/02-components/_searchbar/mando-ui.searchbar'
      );
      searchbar();
      if (PL_DEBUG) console.log(`%c${key} loaded`, 'color: #000; background: yellow;');
    },
  };

  try {
    return modules[key]();
  } catch (e) {
    console.error(`Module ${key} not found.`);
  }
}

function init(modules: HTMLElement[]): void {
  if (!modules) return;
  const moduleList: string[] = [];
  modules.forEach((element) => {
    try {
      const moduleNames: string | undefined = element.dataset.module;
      if (moduleNames) {
        const moduleArray: string[] = JSON.parse(moduleNames.replace(/'/g, '"'));
        moduleArray.forEach((item: string) => {
          const module: string = camelize(item);
          if (moduleList.indexOf(module) === -1) moduleList.push(module);
        });
      }
    } catch (e) {
      console.error('JS name format incorrect in data attribute:');
      console.log(moduleList);
    }
  });

  moduleList.forEach((module) => load(module));
}

export default init;
