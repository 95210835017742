/**
 * Add an 'is-tabbing' class whenever a user is via keyboard
 *
 * This allows us to isolate javascript methods for keyboard only users
 * or to exclude keyboard users
 */

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('is-tabbing');

    window.removeEventListener('keydown', handleFirstTab);
    window.addEventListener('mousedown', handleMouseDownOnce);
  }
}

function handleMouseDownOnce() {
  document.body.classList.remove('is-tabbing');

  window.removeEventListener('mousedown', handleMouseDownOnce);
  window.addEventListener('keydown', handleFirstTab);
}

/**
 * calcInnerVW()
 *
 * Added on load and with a resize listener on body to upate the
 * var(--vw) custom property value to match client inner width.
 *
 * This property can be used where we need 100vw to exclude scrollbar width
 * in order to prevent unnecessary horizontal overflow.
 *
 * NOTE: Overflow-x hidden causes a WCAG fail as some content
 * could flow off screen when text size is enlarged.
 */

function calcInnerVW() {
  document.body.style.setProperty('--vw', `${document.body.clientWidth}px`);
}

function init() {
  calcInnerVW();
  window.addEventListener('resize', calcInnerVW);
  window.addEventListener('keydown', handleFirstTab);
}

export default init;
