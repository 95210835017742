// index.ts

// Styles
import '@/ui/styles/main';

// Logo
import '@/ui/icons/logo/BCSSS-logo.svg';

// Favicons
import '@/ui/images/favicon/android-chrome-192x192.png';
import '@/ui/images/favicon/android-chrome-512x512.png';
import '@/ui/images/favicon/apple-touch-icon.png';
import '@/ui/images/favicon/browserconfig.xml';
import '@/ui/images/favicon/favicon-16x16.png';
import '@/ui/images/favicon/favicon-32x32.png';
import '@/ui/images/favicon/favicon.ico';
import '@/ui/images/favicon/mstile-70x70.png';
import '@/ui/images/favicon/mstile-144x144.png';
import '@/ui/images/favicon/mstile-150x150.png';
import '@/ui/images/favicon/mstile-310x150.png';
import '@/ui/images/favicon/mstile-310x310.png';
import '@/ui/images/favicon/safari-pinned-tab.svg';
import '@/ui/images/favicon/site.webmanifest';

// Fallback card image
import '@/ui/images/default-article-card-image.svg';

// Icon Sprite
/*
 * Note: if new icons are added, be sure to add them
 * to icons.config.js so we can see them inside
 * the Pattern Library
 * */
// Shared - Socials - footer & social share components
import '@/ui/icons/_shared/social/facebook.svg';
import '@/ui/icons/_shared/social/linkedin.svg';
import '@/ui/icons/_shared/social/twitter.svg';
// Shared - Circled socials
import '@/ui/icons/_shared/social/instagram-circled.svg';
import '@/ui/icons/_shared/social/linkedin-circled.svg';
import '@/ui/icons/_shared/social/mail-circled.svg';
import '@/ui/icons/_shared/social/twitter-circled.svg';
import '@/ui/icons/_shared/social/vimeo-circled.svg';
import '@/ui/icons/_shared/social/whatsapp-circled.svg';
import '@/ui/icons/_shared/social/youtube-circled.svg';
// Shared - File type icons
import '@/ui/icons/_shared/file-types/file-avi.svg';
import '@/ui/icons/_shared/file-types/file-csv.svg';
import '@/ui/icons/_shared/file-types/file-doc.svg';
import '@/ui/icons/_shared/file-types/file-jpeg.svg';
import '@/ui/icons/_shared/file-types/file-mov.svg';
import '@/ui/icons/_shared/file-types/file-mp3.svg';
import '@/ui/icons/_shared/file-types/file-mp4.svg';
import '@/ui/icons/_shared/file-types/file-pdf.svg';
import '@/ui/icons/_shared/file-types/file-png.svg';
import '@/ui/icons/_shared/file-types/file-ppt.svg';
import '@/ui/icons/_shared/file-types/file-unknown.svg';
import '@/ui/icons/_shared/file-types/file-wav.svg';
import '@/ui/icons/_shared/file-types/file-xlx.svg';
import '@/ui/icons/_shared/file-types/file-zip.svg';
// General UI icons
import '@/ui/icons/burger-menu.svg';
import '@/ui/icons/down-arrow.svg';
import '@/ui/icons/arrow-left.svg';
import '@/ui/icons/arrow-right.svg';
import '@/ui/icons/close.svg';
import '@/ui/icons/download.svg';
import '@/ui/icons/help.svg';
import '@/ui/icons/info-filled.svg';
import '@/ui/icons/search.svg';

// Umbraco thumbnails
import '@/ui/images/umbraco-thumbnails/2-column-card-container.png';
import '@/ui/images/umbraco-thumbnails/3-column-card-container.png';
import '@/ui/images/umbraco-thumbnails/accordion-container.png';
import '@/ui/images/umbraco-thumbnails/accordion-item.png';
import '@/ui/images/umbraco-thumbnails/audio.png';
import '@/ui/images/umbraco-thumbnails/card.png';
import '@/ui/images/umbraco-thumbnails/document-download-container.png';
import '@/ui/images/umbraco-thumbnails/document-item.png';
import '@/ui/images/umbraco-thumbnails/icon-container.png';
import '@/ui/images/umbraco-thumbnails/icon-item.png';
import '@/ui/images/umbraco-thumbnails/rich-text.png';
import '@/ui/images/umbraco-thumbnails/navigational-card-listing.png';
import '@/ui/images/umbraco-thumbnails/sublayout-centred-column.png';
import '@/ui/images/umbraco-thumbnails/sublayout-page-width.png';

// Scripts
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import 'regenerator-runtime/runtime';

import polyfill from '@/ui/scripts/lib/polyfill';
import accessibility from '@/ui/scripts/lib/accessibility';
import moduleLoader from '@/ui/scripts/module-loader';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    document.getElementsByTagName('html')[0].classList.remove('no-js');

    // Lib
    polyfill.polyfillClosest();
    polyfill.polyfillRemove();
    accessibility();

    // ES6 modules loaded dynamically based on DOM query
    const modules: HTMLElement[] = Array.from(document.querySelectorAll('.js-module'));
    moduleLoader(modules);
  },
  false,
);
